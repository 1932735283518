import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import AnHPForm from "../../core/components/forms/factory/AnHPForm";
import AnFormGroup from "../../core/components/forms/factory/AnFormGroup";
import AnSpinner from "../../core/components/other/AnSpinner";
import { getOffhireDaysBetweenDates, StatusPill } from "../util/first";
import VesselOffhireReportTableWithTimelineConflict from "../components/VesselOffhireReportTableWithTimelineConflict";
import { isFieldEmpty, offhireAttachmentDownloadHandler } from "../services/OffhireReportService";
import { OffhireReportService } from "../services/offhire-services";
import { withRouter } from "./components/withRouter";
// import {
//   getSubmitButton,
//   getOptionsArrayForSelectElement,
// } from "../util/first";


class OffhireReportApprovalForm extends React.Component {
  state = {
    isLoggedIn: true,
    isLoaded: false,
    onProgress: false,
    error: null,

    // isEventTypesLoaded: false,
    // eventTypes: [],
    // isMachineryLoaded: false,
    // machinery: [],

    feilds: {},
    // feildsOptions: {},

    isAllLoaded: 0,
    isStateChanged: false,

    report_status: "",
  };

  labels = {
    offhire_report_commercial_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Offhire Report (Commercial)",
    },
    vessel_name: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Vessel Name",
      type: "text",
      value: "string",
    },
    name: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Name",
      type: "text",
      value: "string",
    },
    vessel_code: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Vessel Code",
      type: "text",
      value: 0,
    },
    voyage_number: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voyage Number",
      type: "text",
      value: 0,
    },
    voyage_status: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voyage Status",
      type: "text",
      value: "string",
    },
    commenced_at: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voayge Commenced At",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.899Z",
    },
    completed_at: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voayge Completed At",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.899Z",
    },
    activity: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Activity",
      type: "text",
      value: "string",
    },
    delay_start_at_gmt: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Start At (GMT)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_start_at_local: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Start At (Local)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_end_at_gmt: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay End At (GMT)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_end_at_local: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay End At (Local)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    reason: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Reason",
      type: "text",
      value: "string",
    },
    tci: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "TCI %",
      type: "text",
      value: 0,
    },
    tco: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "TCO %",
      type: "text",
      value: 0,
    },

    delay_year: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Year",
      type: "text",
      value: 0,
    },

    hours: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Duration (in Hours)",
      type: "text",
      value: 0,
    },
    custom_offhire_days: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Duration (in Days)",
      type: "text",
      value: 0,
    },
    offhire_or_stoppage: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Offhire/Stoppage",
      type: "text",
      // type: "select",
      options: [
        {
          value: true,
          title: "Offhire",
        },
        {
          value: false,
          title: "Stoppage",
        },
      ],
    },
    // custom_offhire_days_group: {
    //   isGroupTitle: false,
    //   readOnly: true,
    //   required: false,
    //   title: "Offhire Days Group",
    //   type: "text",
    //   value: "string",
    // },
    offhire_report_technical_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Offhire Report (Technical)",
    },
    offhire_report_technical_section_p1: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 1:",
    },

    from_datetime: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "From Date Time (GMT)",
      type: "datetime-local",
    },
    to_datetime: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "To Date Time (GMT)",
      type: "datetime-local",
    },
    days: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Days",
      type: "text",
    },
    offhire_report_technical_section_p2: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 2:",
    },
    offhire_type: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Offhire Type",
      // type: "select",
      type: "text",
      // options: [
      //   {
      //     value: "",
      //     title: "NA",
      //   },
      // ],
    },
    events_category: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Events Category",
      type: "text",
      // type: "select",
      // options: [
      //   {
      //     value: "",
      //     title: "Select",
      //   },
      //   // {
      //   //   value: true,
      //   //   title: "Planned",
      //   // },
      //   // {
      //   //   value: false,
      //   //   title: "Unplanned",
      //   // },
      // ],
    },
    is_planned: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Planned/Unplanned",
      type: "select",
      options: [
        {
          value: "",
          title: "Select",
        },
        {
          value: true,
          title: "Planned",
        },
        {
          value: false,
          title: "Unplanned",
        },
      ],
    },
    filler: {
      isGroupTitle: false,
      type: "filler",
    },
    machinery_type: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Machinery Type",
      type: "text",
      // type: "select",
      // options: [
      //   {
      //     value: "",
      //     title: "-NA",
      //   },
      // ],
    },
    sfi_code: {
      isGroupTitle: false,
      title: "SFI Code",
      type: "text",
      readOnly: true,
      required: false,
    },
    machinery_subtype: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Machinery Subtype",
      type: "text",
      // type: "select",
      // options: [
      //   {
      //     value: "",
      //     title: "NA",
      //   },
      // ],
    },
    offhire_report_technical_section_p3: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 3:",
    },
    event: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Event",
      maxlength: "30",
      type: "text",
      value: "string",
    },
    // ...
    // vessel_age: {
    //   isGroupTitle: false,
    //   title: "Vessel Age",
    //   type: "text",
    //   readOnly: true,
    //   required: false,
    // },
    location: {
      isGroupTitle: false,
      title: "Location",
      type: "select",
      options: [
        {
          value: "",
          title: "",
        },
        {
          value: "SEA",
          title: "Sea",
        },
        {
          value: "PORT",
          title: "Port",
        },
      ],
      readOnly: true,
      required: false,
    },
    place: {
      isGroupTitle: false,
      title: "Place",
      type: "text",
      readOnly: true,
      required: false,
    },
    attachment: {
      isGroupTitle: false,
      title: "Attachment",
      type: "file",
      fileAttachmentId: "",
      readOnly: true,
      required: false,
    },    
    // ...
    shipmanager_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Ship Manager Section",
    },
    ship_manager_comment: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Ship Manager Comment",
      type: "textarea",
      value: "string",
      maxlength: "4000",
      col: 12,
    },
    submited_by: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Submitted By",
      type: "text",
    },
    ship_manager_submited_on: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Submitted On",
      type: "datetime-local",
    },
    fleetmanager_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Fleet Manager Section",
    },
    fleet_manager_comment: {
      isGroupTitle: false,
      title: "Fleet Manager Comment",
      type: "textarea",
      value: "string",
      maxlength: "4000",
      col: 12,
    },
    tech_report_status: {
      isGroupTitle: false,
      title: "Approve/Reject",
      type: "select",
      options: [
        {
          value: "",
          title: "SELECT ONE",
        },
        {
          value: "1",
          title: "Approve",
        },
        {
          value: "2",
          title: "Reject",
        },
      ],
    },
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  getTommorowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().slice(0, 10);
  }

  groupMachineriesByType = (machineries) => {
    // debugger;
    // console.log("machineries");
    let groupedMachineries = {};

    (machineries instanceof Array) && machineries.forEach((machinery) => {
      groupedMachineries[machinery.machinery_type.id] = groupedMachineries[
        machinery.machinery_type.id
      ] ?? { id: "", name: "", sfi_code: "", machineries: [] };

      groupedMachineries[machinery.machinery_type.id].id =
        machinery.machinery_type.id;
      groupedMachineries[machinery.machinery_type.id].name =
        machinery.machinery_type.name;
      groupedMachineries[machinery.machinery_type.id].sfi_code =
        machinery.machinery_type.sfi_code;
      groupedMachineries[machinery.machinery_type.id].machineries.push(
        machinery
      );
    });

    // Convert to array
    let groupedMachineryTypes = [];
    for (let key in groupedMachineries) {
      groupedMachineryTypes.push(groupedMachineries[key]);
    }
    return groupedMachineryTypes;
  };

  /**
   * Relation removed, so more required form 26-Jan-2023
   * @param {*} eventTypes 
   * @returns 
   */
  extractEventsCategoriesFromEventTypes = (eventTypes) => {
    let eventsCategories = {};
    (eventTypes instanceof Array) && eventTypes?.forEach((eventType) => {
      eventsCategories[eventType.offhire_events_category.id] =
        eventType.offhire_events_category;
    });

    // Convert to array
    let eventsCategoriesArray = [];
    for (let key in eventsCategories) {
      eventsCategoriesArray.push(eventsCategories[key]);
    }
    return eventsCategoriesArray;
  };

  getOptionsArrayForSelectElement = (optionsInJSON, property_name = "name") => {
    // debugger;
    // console.log("optionsInJSON");
    let optionsArray = [];
    for (let aOption of optionsInJSON) {
      optionsArray.push({
        value: aOption.id,
        title: aOption[property_name],
        label: aOption[property_name],
      });
    }
    return optionsArray;
  };

  getFieldsMap = () => {
    // front:back
    return {
      vessel_name: "vessel_name",
      name: "name",
      vessel_code: "vessel_code",
      voyage_number: "voyage_number",
      voyage_status: "voyage_status",
      commenced_at: "commenced_at",
      completed_at: "completed_at",
      activity: "activity",
      offhire_or_stoppage: "activity",
      delay_start_at_gmt: "commercial_delay_start_at_gmt",
      delay_end_at_gmt: "commercial_delay_end_at_gmt",
      reason: "commercial_reason",
      tci: "tci",
      tco: "tco",
      // id: "id",
      delay_year: "delay_year",
      delay_start_at_local: "delay_start_at_local",
      delay_end_at_local: "delay_end_at_local",
      hours: "hours",
      custom_offhire_days: "custom_offhire_days",
      custom_offhire_days_group: "custom_offhire_days_group",
      // ------------------

      id: "offhire_report_commercial_id",
      offhire_type: "event_type_name", //_id",
      machinery_subtype: "machinery_name", //_id",
      from_datetime: "delay_start_at_gmt",
      to_datetime: "delay_end_at_gmt",
      days: "duration_in_days",
      event: "event",
      // vessel_age: "vessel_age",
      location: "location",
      place: "place_name",
      ship_manager_comment: "ship_manager_comment",
      //--------------------------
      machinery_type: "machinery_type_name", //_id",
      sfi_code: "sfi_code",
      events_category: "events_category_name", //_id",
      is_planned: "is_planned",
      attachment: "attachment_id",
      tech_report_status: "report_status",
      fleet_manager_comment: "fleet_manager_comment",
      submited_by: "ship_manager_name",
      ship_manager_submited_on: "ship_manager_submited_on",
    };
  };

  preProcessValuesForFeilds = (report) => {
    return {
      ...report,
      // vessel_age: "",
      location: report.offhire_report_technical?.location ?? "",
      // sea_id: report.offhire_report_technical?.sea_id ?? "",
      // port_id: report.offhire_report_technical?.port_id ?? "",
      place_name:
        report.offhire_report_technical?.location?.toUpperCase() === "SEA"
          ? report.offhire_report_technical?.sea?.name ?? ""
          : report.offhire_report_technical?.port?.name ?? "",

      hours: report.hours?.toFixed(2),
      // days: report.custom_offhire_days.toFixed(2),
      custom_offhire_days: report.custom_offhire_days?.toFixed(2),

      machinery_id: report.offhire_report_technical?.machinery_id,
      machinery_name: report.offhire_report_technical?.machinery?.name,

      machinery_type_id:
        report.offhire_report_technical?.machinery?.machinery_type_id,
      sfi_code:
        report.offhire_report_technical?.machinery?.machinery_type?.sfi_code,
      machinery_type_name:
        report.offhire_report_technical?.machinery?.machinery_type.name,

      event_type_id: report.offhire_report_technical?.event_type_id,
      event_type_name: report.offhire_report_technical?.event_type?.name,

      events_category_id:
        report.offhire_report_technical?.events_category_id ?? "",
      events_category_name:
        report.offhire_report_technical?.events_category
          ?.name,
      is_planned: report.offhire_report_technical?.is_planned,
      ship_manager_comment:
        report.offhire_report_technical?.ship_manager_comment ?? "",
      fleet_manager_comment:
        report.offhire_report_technical?.fleet_manager_comment ?? "",
      ship_manager_name:
        report.offhire_report_technical?.ship_manager?.name ?? "-",
      ship_manager_submited_on:
        report.offhire_report_technical?.ship_manager_updated_report_at?.slice(
          0,
          16
        ) ?? "",

      report_status: report.offhire_report_technical?.report_status ?? "",
      offhire_report_commercial_id: report.id,
      // resolving the denesting key conflicts
      event: report.offhire_report_technical?.event ?? "",
      delay_start_at_gmt:
        report.offhire_report_technical?.delay_start_at_gmt ?? "",
      delay_end_at_gmt: report.offhire_report_technical?.delay_end_at_gmt ?? "",
      duration_in_days: getOffhireDaysBetweenDates(
        report.offhire_report_technical?.delay_start_at_gmt,
        report.offhire_report_technical?.delay_end_at_gmt,
        report.tci
      ),
      commercial_reason: report.reason ?? "",
      commercial_delay_start_at_gmt: report.delay_start_at_gmt ?? "",
      commercial_delay_end_at_gmt: report.delay_end_at_gmt ?? "",
      attachment_id: {files:null, fileAttachmentId: report.offhire_report_technical?.attachment_id},

    };
  };

  updateAllFeildsValue = (responseFeildsObjectOrg) => {
    // console.log("responseFeildsObjectOrg", responseFeildsObjectOrg);
    const responseFeildsObject = this.preProcessValuesForFeilds(
      responseFeildsObjectOrg
    );
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    let timestamp = null;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];

      if (value !== undefined) {
        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    // this.setState({ isLoaded: true, feilds, last_sync_timestamp: timestamp });
    this.setState({
      isAllLoaded: this.state.isAllLoaded + 1,
      feilds,
      last_sync_timestamp: timestamp,
      report_status: responseFeildsObjectOrg?.report_status,
    });
  };

  getPostRequestObject = () => {
    const feildsMap = this.getFieldsMap();
    const formFeildsState = this.state.feilds;
    let postRequestObject = {};
    for (let key in feildsMap) {
      const FIELD_KEY = feildsMap[key];
      const TYPE = this.labels[key]?.type;
      let value = formFeildsState[key];
      value = TYPE === "file" ? value?.fileAttachmentId : value; 
      const is_required = this.labels[key]?.required === false ? false : true;
      if (value !== undefined) {
        if (isFieldEmpty(value, TYPE) === 0 && is_required) {
          const feildName = key.split("_").join(" ").toUpperCase();
          alert("Error:" + feildName + " value is empty");
          return null;
        }
        postRequestObject[FIELD_KEY] = value;
      }
    }
    return postRequestObject;
  };

  responceError = (error) => {
    // alert("Error: " + error.response.status);
    if (error.response.status === 401) {
      this.setState({ isLoggedIn: false });
    }
    if (error.response.status === 404) {
      this.setState({
        onProgress: false,
        isLoaded: true,
        error: "No data found for the given dates.",
      });
    } else {
      this.setState({
        onProgress: false,
        isLoaded: true,
        error: "Error: " + error.response.statusText,
      });
    }
  };

  async componentDidMount() {
 
    const { report } = this.props;
    this.updateAllFeildsValue(report);
  }
 
  handleFileDownload = (e) => {
    const { feilds } = this.state;
    offhireAttachmentDownloadHandler(e, feilds);
  }

  handleValueChange = (e) => {
    const KEY = e.target.name;
    let value = e.target.value.toString();

    // debugger;
    let {
      feilds,
      feildsOptions,
      machineryTypes,
      eventTypes,
      isStateChanged,
      reportsWithTimelineConflits,
    } = this.state;

    feilds[KEY] = value;
    // Update fields(Feild's Value) and feildsOptions(Feilds's Options) in state
    this.setState({
      feilds,
      feildsOptions,
      isStateChanged,
    });
  };

  // Form submit handler
  handleFormSubmit = (event) => {
    // debugger;
    // prevent default form submit
    event.preventDefault();
    // Mark the state as submitted to show the spinner
    this.setState({ onProgress: true });
    // Create object from form data
    const postRequestObject = this.getPostRequestObject();
    // Empty form validation
    if (postRequestObject == null) {
      this.setState({ onProgress: false });
      return;
    }
    // console.log("postRequestObject");
    // console.log(postRequestObject);
    // return;
    OffhireReportService.approveTechical(postRequestObject)
      .then(
        (response) => {
          // console.log("Post Response");
          // console.log(response);
          // console.log(this.props);
          if (response.data) {
            this.updateAllFeildsValue(response.data);
            this.setState({ onProgress: false });
          }
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            alert("Successfully Submitted");
          }

          //   console.log(response);
        },
        (error) => {
          let errorMessage = `Error: ${error.response.status} ${
            error.response?.data?.detail ?? error.response?.statusText
          }`;
          // console.log("Error");
          // console.log(error.response);
          if (error.response.status === 401) {
            this.setState({ isLoggedIn: false });
          }
          if (
            error.response.status === 409 &&
            error.response?.data?.length > 0
          ) {
            errorMessage =
              "Given time period is overlapping with already existing event";
            this.setState({
              reportsWithTimelineConflits: error.response.data,
              isReportsWithTimelineConflitsLoaded: true,
            });
          }
          alert(errorMessage);
          this.setState({
            onProgress: false,
            isLoaded: true,
            error: errorMessage,
          });
          setTimeout(() => {
            this.setState({ error: null });
          }, 3000);
        }
      );
  };

  getSubmitButton = (buttonTitle, isSizeSmall = false) => {
    return (
      <div className="form-group row  m-0 p-0 pt-1 justify-content-end">
        <div className="col-sm-12 col-md-6">
          {this.state.onProgress && (
            <React.Fragment>
              <span className="text-danger" style={{ fontSize: "0.7rem" }}>
                {" "}
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                <span className="sr-only">Generating...</span>
              </span>
            </React.Fragment>
          )}
          {!this.state.error && (
            <span className="text-danger" style={{ fontSize: "0.7rem" }}>
              {this.state.error}
            </span>
          )}
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <input
            // type="submit"
            onClick={this.handleFormSubmit}
            className="form-control btn btn-outline-primary btn-sm p-0"
            name="submit"
            id="submit"
            value={buttonTitle}
            style={{ fontSize: "0.6rem" }}
            disabled={this.state.onProgress}
          />
        </div>
      </div>
    );
  };

  // Form
  render() {
    const {
      feilds,
      feildsOptions,
      isLoggedIn,
      isAllLoaded,
      reportsWithTimelineConflits,
    } = this.state;
    // console.log(this.state);
    // Login check
    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    // Loading check
    // if (isAllLoaded < 3) return <AnSpinner />;
    if (isAllLoaded < 1) return <AnSpinner />;

    let formFeildsArr = [];
    for (let key in this.labels) {
      // debugger;
      // Default Select Option
      const selectOptions = this.labels[key].options ?? [
        { value: "", title: "--Select--", label: "--Select--" },
      ];
      // Form Input Feilds
      formFeildsArr.push({
        ...this.labels[key],
        name: key,
        value: feilds[key],
        onChangeHandler: this.handleValueChange,
        handleDownload: this.handleFileDownload,
        options: selectOptions, //[...selectOptions, ...(feildsOptions[key] ?? [])],
        // options: feildsOptions[key],
      });
    }
    // console.log(formFeildsArr);
    // const elementsInRow = 3;
    return (
      <React.Fragment>
        <div className="row justify-content-end">
          <div className="col-auto">
            <StatusPill status={this.state?.report_status} />
          </div>
        </div>
        <AnHPForm onBack={this.props.onBack} onSubmit={this.handleFormSubmit}>
          <div className="row">
            {formFeildsArr.map((attribute, index) => {
              return (
                <React.Fragment>
                  {/* {index % elementsInRow === 0 && (<div className="form-row">)} */}
                  <AnFormGroup attribute={attribute} key={index} />
                  {/* {index % elementsInRow === 2 && (</div>)} */}
                </React.Fragment>
              );
            })}
          </div>
          <VesselOffhireReportTableWithTimelineConflict
            reports={reportsWithTimelineConflits}
          />
          {this.getSubmitButton("Submit")}
        </AnHPForm>
        <div className="row justify-content-start">
          <div className="col-auto">
            <StatusPill status={this.state?.report_status} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(OffhireReportApprovalForm);
