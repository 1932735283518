import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import AnHPForm from "../../../core/components/forms/factory/AnHPForm";
import AnFormGroup from "../../../core/components/forms/factory/AnFormGroup";
import VesselOffhireReportTableWithTimelineConflict from "../../components/VesselOffhireReportTableWithTimelineConflict";
import AnSpinner from "../../../core/components/other/AnSpinner";
import { getOffhireDaysBetweenDates, StatusPill } from "../../util/first";
import OffhireReportFormService from "../../services/OffhireReportFormService";
import { isFieldEmpty, offhireAttachmentDownloadHandler } from "../../services/OffhireReportService";
import { errorHandler } from "../../../core/utility/http-util";
import { VesselService } from "../../services/offhire-services";
import FilterSelect from "../../components/FilterSelect";
import AnFormSubmitButton from "../components/AnFormSubmitButton";
import { FORM_FIELDS_COMMERCIAL_TECHNICAL } from "./form-fields";
import { AnFormConfUtil } from "../../../core/utility/form-conf.util";
import { AnOffhireMachineryUtil } from "../../util/offhire.util";
import { FormValueCommercialTechnical } from "./form-data";
import { withRouter } from "../components/withRouter";
import { AnFormStatus } from "../components/AnFormStatus";
// import AnDatalistInput from "../../../core/components/forms/factory/AnDatalistInput";
// import DataListInput from "react-datalist-input";


class CommercialTechnicalForm extends React.Component {
  offhireReportFormService;
  state = {
    isLoggedIn: true,
    isLoaded: false,
    onProgress: false,
    error: null,

    isEventTypesLoaded: false,
    eventTypes: [],
    isMachineryLoaded: false,
    machinery: [],

    feilds: {},
    feildsOptions: {},

    vesselOptions: [],

    isAllLoaded: 0,
    isStateChanged: false,

    report_status: "",
    reportsWithTimelineConflits: [],
    isReportsWithTimelineConflitsLoaded: false,
  };

  

  labels = FORM_FIELDS_COMMERCIAL_TECHNICAL;

  constructor(props) {
    super(props);
    this.state["feilds"] = AnFormConfUtil.initState(this.labels);
  }

  getFieldsMap = FormValueCommercialTechnical.fieldMap;
  preProcessValuesForFeilds = FormValueCommercialTechnical.preprocess;

  updateAllFeildsValue = (responseFeildsObjectOrg) => {
    const responseFeildsObject = this.preProcessValuesForFeilds(
      responseFeildsObjectOrg
    );
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    let timestamp = null;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];

      // Default values for date feilds of technical report
      if (
        (feildKey === "delay_start_at_gmt" ||
          feildKey === "delay_end_at_gmt") &&
        (value === null || value === undefined || value === "")
      ) {
        value = responseFeildsObject["commercial_" + feildKey];
      }
      if (feildKey === "duration_in_days") {
        // debugger;
        // console.log(responseFeildsObject);
        // console.log("duration_in_days", value);
        //For the first time the value is not availablem
        if (
          responseFeildsObject["delay_start_at_gmt"] &&
          responseFeildsObject["delay_end_at_gmt"]
        ) {
          value = getOffhireDaysBetweenDates(
            responseFeildsObject["delay_start_at_gmt"],
            responseFeildsObject["delay_end_at_gmt"],
            responseFeildsObject["tci"]
          );
        } else if (
          responseFeildsObject["commercial_delay_start_at_gmt"] &&
          responseFeildsObject["commercial_delay_end_at_gmt"]
        ) {
          value = getOffhireDaysBetweenDates(
            responseFeildsObject["commercial_delay_start_at_gmt"],
            responseFeildsObject["commercial_delay_end_at_gmt"],
            responseFeildsObject["tci"]
          );
        }
        if (value === null || value === undefined || value === "") {
          value = responseFeildsObject["custom_offhire_days"];
        }
      }

      if (value !== undefined) {
        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    // this.setState({ isLoaded: true, feilds, last_sync_timestamp: timestamp });
    this.setState({
      isAllLoaded: this.state.isAllLoaded + 1,
      feilds,
      last_sync_timestamp: timestamp,
      report_status: responseFeildsObjectOrg?.report_status,
    });
  };

  getPostRequestObject = () => {
    const feildsMap = this.getFieldsMap();
    const formFeildsState = this.state.feilds;
    let postRequestObject = {};

    // Form Submit validation
    // debugger;
    const diff = getOffhireDaysBetweenDates(
      formFeildsState["from_datetime"],
      formFeildsState["to_datetime"]
    );
    if (diff <= 0) {
      alert("'To Date Time' should be greater than 'From Date Time'");
      return;
    }

    for (let key in feildsMap) {
      const FIELD_KEY = feildsMap[key];
      const TYPE = this.labels[key]?.type;
      let value = formFeildsState[key];
      value = TYPE === "file" ? value?.fileAttachmentId : value; 
      const is_required = this.labels[key]?.required === false ? false : true;
      if (value !== undefined) {
        if (isFieldEmpty(value, TYPE) === 0 && is_required) {
          const feildName = key.split("_").join(" ").toUpperCase();
          alert("Error:" + feildName + " value is empty");
          return null;
        }
        postRequestObject[FIELD_KEY] = value;
      }
    }
    return postRequestObject;
  };

  responceError = (error) => errorHandler(
    error, 
    this.setState.bind(this), 
    this.toastRef, 
    null, 
    true
    );

  async componentDidMount() {
   OffhireReportFormService.getFormData().then((responses)=>{
      let dataSet = {};
      let feildsOptionsSet = {};
      const dataSetKeys = [ 'eventCategories', 'eventTypes', 'seas', 'machinery', 'ports'];
      const feildsOptionsSetKeys =  ['events_category', 'offhire_type', 'sea', 'machinery_subtype', 'port'];

      responses.forEach((response, index)=>{
        const keyy = dataSetKeys[index];
        // out of five, last two are already fetched data and others are HTTP response obj
        const data = (keyy==='machinery' || keyy==='ports' ? response : response.data) ?? [];

        dataSet[keyy] = data;
        const dropdownOptions = AnFormConfUtil.getOptionsArrayForSelectElement(data);
        feildsOptionsSet[feildsOptionsSetKeys[index]] = dropdownOptions ?? [];

        if(keyy==='machinery'){
          dataSet['machineryTypes'] = AnOffhireMachineryUtil.groupMachineriesByType(data);
          feildsOptionsSet['machinery_type'] = AnFormConfUtil.getOptionsArrayForSelectElement(dataSet['machineryTypes']);
        }
      });
      // Updating the component state
      this.setState({
        ...dataSet,
        feildsOptions: {
          ...this.state.feildsOptions,
          ...feildsOptionsSet
        },
        isAllLoaded: 3,
      });  
  },
  (error)=> this.responceError(error)
  );

  VesselService.getAsOptions()
  .then((response) => {
    if (response.status === 200) {
      this.setState({
        vesselOptions: response.data,
      });
    }
  });

    const { report } = this.props;
    this.updateAllFeildsValue(report);
  }

  handleFileDownload = (e) => {
    const { feilds } = this.state;
    offhireAttachmentDownloadHandler(e, feilds);
  }

  handleVesselChange = (selectedOption) => {
    if (selectedOption.id === "report_filter_vessel") {
      const { label: VESSEL_NAME, value: VESSEL_CODE } = selectedOption.value;
      this.handleValueChange({ target: { name: "vessel_name", value: VESSEL_NAME } });
      this.handleValueChange({ target: { name: "vessel_code", value: VESSEL_CODE } });
    }
  };

  handleValueChange = (e) => {
    const KEY = e.target.name;
    let value = e.target.value.toString();

    // debugger;
    let {
      feilds,
      feildsOptions,
      machineryTypes,
      eventTypes,
      isStateChanged,
      reportsWithTimelineConflits,
    } = this.state;

    if(KEY==='attachment' && e.target.files){
      value = { files: e.target.files };
      // if(value?.[0].size < 209){
      // if(value?.[0].size < 2097150){
      if(value.files?.[0].size < 5242800){
      // feilds['attachment'].files = e.target.files;
      const fileAttachmentId = feilds?.[KEY]?.fileAttachmentId ?? 0;
      OffhireReportFormService.uploadAttachment(e.target.files, fileAttachmentId)
      .then((response)=>{
        // debugger;
        let {feilds} = this.state;
        feilds[KEY].fileAttachmentId = response?.data?.fileAttachmentId ?? "";
        this.setState({ feilds });
      }).catch(()=>{
        alert('Upload Failed!')
      });
    }else{
      alert('Please select a file of size less-than 5MB!');
      return;
    }
    //
      // return;
    } else if (KEY === "machinery_type") {
      if (!!value) {
        const selectedMachineryType = machineryTypes.find(
          (element) => element.id === parseInt(value)
        );
        feildsOptions.machinery_subtype = AnFormConfUtil.getOptionsArrayForSelectElement(
          // machineryTypes[value].machineries
          selectedMachineryType.machineries
        );
        feilds.sfi_code = selectedMachineryType?.sfi_code;
      } else {
        feildsOptions.machinery_subtype = [];
        feilds["sfi_code"] = "";
        feilds["machinery_subtype"] = "";
      }
      isStateChanged = !isStateChanged;
    } else if (KEY === "offhire_type") {
      const selectedEventType = eventTypes.find(
        (element) => element.id === parseInt(value)
      );
      // feilds["events_category"] = selectedEventType?.offhire_events_category_id;
      isStateChanged = !isStateChanged;
    } else if (KEY === "from_datetime") {
      const diff = getOffhireDaysBetweenDates(
        value,
        feilds["to_datetime"],
        feilds["tci"]
      );
      // if (diff < 0) {
      //   alert("To date should be greater than from date");
      //   return;
      // }
      feilds["days"] = diff; //?.toFixed(2);
    } else if (KEY === "to_datetime") {
      const diff = getOffhireDaysBetweenDates(
        feilds["from_datetime"],
        value,
        feilds["tci"]
      );
      // if (diff < 0) {
      //   alert("To date should be greater than from date");
      //   return;
      // }
      feilds["days"] = diff; //?.toFixed(2);
    } else if (KEY === "location") {
      feilds["place"] = "";
      feilds["port"] = "";
      feilds["sea"] = "";
    } else if (KEY === "port" || KEY === "sea") {
      feilds["place"] = value;
    }

    feilds[KEY] = value;
    // Update fields(Feild's Value) and feildsOptions(Feilds's Options) in state
    this.setState({
      feilds,
      feildsOptions,
      isStateChanged,
    });
  };

  // getDaysBetweenDates = (startDate, endDate) => {
  //   if (startDate === "" || endDate === "") {
  //     return "";
  //   }
  //   let start = new Date(startDate);
  //   let end = new Date(endDate);
  //   let days = (end - start) / (1000 * 60 * 60 * 24);
  //   return days;
  // };

  // Form submit handler
  handleFormSubmit = (event) => {
    // debugger;
    // prevent default form submit
    event.preventDefault();
    // Mark the state as submitted to show the spinner
    this.setState({ onProgress: true });
    // Create object from form data
    const postRequestObject = this.getPostRequestObject();
    // Empty form validation
    if (postRequestObject == null) {
      this.setState({ onProgress: false });
      return;
    }
    OffhireReportFormService.createReport({
      commercial_form: postRequestObject,
      technical_form: postRequestObject,
    }, true)
      .then(
        (response) => {
          if (response.data && response.data.length > 0) {
            this.updateAllFeildsValue(response.data[0]);
            this.setState({ onProgress: false });
          }
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            alert("Successfully Submitted");
          }
        },
        (error) => {
          let errorMessage = `Error: ${error.response.status} ${
            error.response?.data?.detail ?? error.response?.statusText
          }`;
          if (error.response.status === 401) {
            this.setState({ isLoggedIn: false });
          }
          if (
            error.response.status === 409 &&
            error.response?.data?.length > 0
          ) {
            errorMessage =
              "Given time period is overlapping with already existing event";
            this.setState({
              reportsWithTimelineConflits: error.response.data,
              isReportsWithTimelineConflitsLoaded: true,
            });
          }
          alert(errorMessage);
          this.setState({
            onProgress: false,
            isLoaded: true,
            error: errorMessage,
          });
          setTimeout(() => {
            this.setState({ error: null });
          }, 3000);
        }
      );
  };

 

  // Form
  render() {
    const {
      feilds,
      feildsOptions,
      vesselOptions,
      isLoggedIn,
      isAllLoaded,
      reportsWithTimelineConflits,
    } = this.state;
    // console.log(this.state);
    // Login check
    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    // Loading check
    if (isAllLoaded < 3) return <AnSpinner />;

    let formFeildsArr = AnFormConfUtil.fieldAttributes(
      this.labels,
      feilds,
      feildsOptions,
      (fieldKey, fieldValues) => {
        const FILTER = ((fieldKey === "sea" || fieldKey === "port") && fieldKey !== fieldValues["location"]?.toLowerCase()) 
        || ((fieldKey === "sfi_code" || fieldKey === "machinery_subtype") && !fieldValues["machinery_type"]);
        return !FILTER;
      },
      this.handleValueChange,
      this.handleFileDownload
    );

    return (
      <AnFormStatus status={this.state?.report_status}>
        <AnHPForm onBack={this.props.onBack} onSubmit={this.handleFormSubmit}>
          
          <div className="row">
          <div className="col-sm-6 col-md-auto">
            <FilterSelect
              label="Vessel"
              // closeMenuOnSelect={false}
              // isMulti={true}
              id="report_filter_vessel"
              onChange={this.handleVesselChange}
              options={vesselOptions?.map(vesselOpt => ({
                value: vesselOpt.vessel_code,
                label: vesselOpt.vessel_name,
              }))}
            ></FilterSelect>
          </div>

            {formFeildsArr.map((attribute, index) => {
              return <AnFormGroup attribute={attribute} key={index} />;
            })}
          </div>

          {/* <OffhireReportTechnicalFormFormat feilds={formFeildsArr} /> */}
          <VesselOffhireReportTableWithTimelineConflict
            reports={reportsWithTimelineConflits}
          />

          <AnFormSubmitButton 
          label="Submit" 
          onSubmit={this.handleFormSubmit} 
          inProgress={this.state.onProgress} 
          error={this.state.error}/>

        </AnHPForm>
      </AnFormStatus>
    );
  }
}

export default withRouter(CommercialTechnicalForm);
