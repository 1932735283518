import FilterSelect from "../FilterSelect";

export const MonthFilter = ({ defaultValue, onChange }) => {
    return <FilterSelect
        label="Month"
        closeMenuOnSelect={false}
        isMulti={true}
        id="report_filter_month"
        onChange={onChange}
        options={[
        { value: "", label: "All" },
        { value: "1", label: "JAN" },
        { value: "2", label: "FEB" },
        { value: "3", label: "MAR" },
        { value: "4", label: "APR" },
        { value: "5", label: "MAY" },
        { value: "6", label: "JUN" },
        { value: "7", label: "JUL" },
        { value: "8", label: "AUG" },
        { value: "9", label: "SEP" },
        { value: "10", label: "OCT" },
        { value: "11", label: "NOV" },
        { value: "12", label: "DEC" },
        ]}
        defaultValue={defaultValue}
    ></FilterSelect>
};
