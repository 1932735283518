import CommonListViewScreen from "../../core/pages/CommonListViewScreen";

function RemovedReportsScreenSimple(props) {
  return (
    <CommonListViewScreen
      path="/port/all" // api
      paginatedPath="/port/paged" // api
      dataPreprocessor={{
        mainTitle: "Removed Reports",
        path: "/offhire/port", // route
        columns: [
          { header: "ID", field: "id" },
          { header: "Name", field: "name" },
          { header: "UN Code", field: "un_code" },
          { header: "Country ID", field: "country_id" },
        ],
      }}
    >
      {/* <PortForm /> */}
    </CommonListViewScreen>
  );
}

export default RemovedReportsScreenSimple;
