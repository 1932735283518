import { useEffect, useState } from "react";
import { VesselService } from "../../services/offhire-services";
import FilterSelect from "../FilterSelect";

export const VesselFilter = ({ defaultValue, onChange }) => {

    const [vesselOptions, setVesselOptions] = useState([]);

    useEffect(() => {
        // Fetch vessel options
        // dispatch(fetchVesselOptions());
        VesselService.getAsOptions()
        .then((response) => {
            if (response.status === 200) {
                setVesselOptions(response.data);
            }
        });
    }, []);

    return <FilterSelect
    label="Vessel"
    closeMenuOnSelect={false}
    isMulti={true}
    id="report_filter_vessel"
    onChange={onChange}
    options={
      vesselOptions?.length > 0
        ? vesselOptions.map((vesselOpt) => {
            return {
              value: vesselOpt.vessel_code,
              label: vesselOpt.vessel_name,
            };
          })
        : { value: '', label: '' }
    }
    defaultValue={defaultValue}
  ></FilterSelect>
};