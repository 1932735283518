import { EachItem } from "../../../core/components/hoc/EachItem";
import { OffhireTableDef } from "../../constants/reports.table-def";

export const ReportTableHead = ({ columns }) => {

    return (
        <thead>
            {/* head row 1 */}
            <tr
            // className="thead-light"
            >
            <th colSpan="4"></th>
            <th colSpan="2">FROM (GMT)</th>
            <th colSpan="2">TO (GMT)</th>
            <th colSpan="1"></th>
            <th className="table-secondary" colSpan="6">
                REASON
            </th>
            <th colSpan="1"></th>
            </tr>
            {/* head row 2 */}
            <tr
            // className="bg-info text-light"
            >
            <EachItem of={columns} render={(column, index) => 
                <th key={"header_" + index} scope="col" className={`${column.className}`}>
                {column.title}
                </th>
            }/>
            </tr>
        </thead>
    );
};