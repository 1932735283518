import { tr } from "date-fns/locale";

export const FORM_FIELDS_COMMERCIAL_TECHNICAL = {
    offhire_report_commercial_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Offhire Report (Commercial)",
    },
    vessel_name: {
      isGroupTitle: false,
      readOnly: true,
      required: true,
      title: "Vessel Name",
      type: "text",
      // value: "string",
    },
    name: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: false,
      title: "Employment",
      type: "text",
      // value: "string",
    },
    vessel_code: {
      isGroupTitle: false,
      readOnly: true,
      required: true,
      title: "Vessel Code",
      type: "text",
      // value: 0,
    },
    voyage_number: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: false,
      title: "Voyage Number",
      type: "text",
      // value: 0,
    },
    voyage_status: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: false,
      title: "Voyage Status",
      type: "text",
      // value: "string",
    },
    commenced_at: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: false,
      title: "Voayge Commenced At",
      type: "datetime-local",
      // value: "2022-02-23T23:25:30.899Z",
    },
    completed_at: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: false,
      title: "Voayge Completed At",
      type: "datetime-local",
      // value: "2022-02-23T23:25:30.899Z",
    },
    activity: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: true,
      title: "Activity",
      type: "text",
      // value: "string",
    },
    // delay_start_at_gmt: {
    //   isGroupTitle: false,
    //   readOnly: false, //true,
    //   required: false,
    //   title: "Delay Start At (GMT)",
    //   type: "datetime-local",
    //   // value: "2022-02-23T23:25:30.900Z",
    // },
    // delay_start_at_local: {
    //   isGroupTitle: false,
    //   readOnly: false, //true,
    //   required: false,
    //   title: "Delay Start At (Local)",
    //   type: "datetime-local",
    //   // value: "2022-02-23T23:25:30.900Z",
    // },
    // delay_end_at_gmt: {
    //   isGroupTitle: false,
    //   readOnly: false, //true,
    //   required: false,
    //   title: "Delay End At (GMT)",
    //   type: "datetime-local",
    //   // value: "2022-02-23T23:25:30.900Z",
    // },
    // delay_end_at_local: {
    //   isGroupTitle: false,
    //   readOnly: false, //true,
    //   required: false,
    //   title: "Delay End At (Local)",
    //   type: "datetime-local",
    //   // value: "2022-02-23T23:25:30.900Z",
    // },
    reason: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: true,
      title: "Reason",
      type: "text",
      // value: "string",
    },
    tci: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: true,
      title: "TCI %",
      type: "text",
      // value: 0,
    },
    tco: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: false,
      title: "TCO %",
      type: "text",
      // value: 0,
    },

    delay_year: {
      isGroupTitle: false,
      readOnly: false, //true,
      required: false,
      title: "Delay Year",
      type: "text",
      // value: 0,
    },
    // hours: {
    //   isGroupTitle: false,
    //   readOnly: true,
    //   required: false,
    //   title: "Delay Duration (in Hours)",
    //   type: "text",
    //   // value: 0,
    // },
    // custom_offhire_days: {
    //   isGroupTitle: false,
    //   readOnly: true,
    //   required: false,
    //   title: "Delay Duration (in Days)",
    //   type: "text",
    //   // value: 0,
    // },
    offhire_or_stoppage: {
      isGroupTitle: false,
      readOnly: false,// true,
      required: true,
      title: "Offhire/Stoppage",
      type: "select",
      // type: "select",
      options: [
        {
          value: "OFFHIRE",
          title: "Offhire",
        },
        {
          value: "STOPPAGE",
          title: "Stoppage",
        },
      ],
    },
    offhire_report_technical_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Offhire Report (Technical)",
    },
    offhire_report_technical_section_p1: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 1:",
    },

    from_datetime: {
      isGroupTitle: false,
      title: "From Date Time (GMT)",
      type: "datetime-local",
      required: true,
    },
    to_datetime: {
      isGroupTitle: false,
      title: "To Date Time (GMT)",
      type: "datetime-local",
      required: true,
    },
    days: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Days",
      type: "text",
    },
    offhire_report_technical_section_p2: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 2:",
    },
    offhire_type: {
      isGroupTitle: false,
      title: "Offhire Type",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Offhire Type-",
        },
      ],
      required: true,
    },
    events_category: {
      isGroupTitle: false,
      // readOnly: true,
      required: true,
      title: "Events Category",
      type: "select",
      // type: "select",
    options: [
      {
        value: "",
        title: "Select",
      },
      // {
      //   value: true,
      //   title: "Planned",
      // },
      // {
      //   value: false,
      //   title: "Unplanned",
      // },
    ],
    required: true,
    },
    is_planned: {
      isGroupTitle: false,
      title: "Planned/Unplanned",
      type: "select",
      options: [
        {
          value: "",
          title: "Select",
        },
        {
          value: true,
          title: "Planned",
        },
        {
          value: false,
          title: "Unplanned",
        },
      ],
    },
    filler: {
      isGroupTitle: false,
      type: "filler",
      // col: 6,
    },
    machinery_type: {
      isGroupTitle: false,
      title: "Machinery Type",
      type: "search-select",
      options: [
        {
          value: "",
          title: "-Select Machinery Type-",
        },
      ],
    },
    sfi_code: {
      isGroupTitle: false,
      title: "SFI Code",
      type: "text",
      readOnly: true,
      required: false,
    },
    machinery_subtype: {
      isGroupTitle: false,
      title: "Machinery Subtype",
      type: "search-select",
      options: [
        {
          value: "",
          title: "-Select Machinery Subtype-",
        },
      ],
    },
    offhire_report_technical_section_p3: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 3:",
    },
    event: {
      isGroupTitle: false,
      title: "Event",
      maxlength: "30",
      type: "text",
      // value: "string",
    },
    // ...
    // vessel_age: {
    //   isGroupTitle: false,
    //   title: "Vessel Age",
    //   type: "text",
    //   readOnly: true,
    //   required: false,
    // },
    location: {
      isGroupTitle: false,
      title: "Location",
      type: "select",
      options: [
        {
          value: "",
          title: "-- Select Location --",
        },
        {
          value: "SEA",
          title: "Sea",
        },
        {
          value: "PORT",
          title: "Port",
        },
      ],
      // readOnly: true,
      required: true,
    },
    // place: {
    //   isGroupTitle: false,
    //   title: "Place",
    //   type: "datalist-input",
    //   options: [
    //     {
    //       value: "",
    //       title: "-- Select Place --",
    //     },
    //   ],
    //   linkSelectByKey: "port",
    //   link: [
    //     {
    //       key: "sea",
    //       link: "sea/all",
    //       fetch: "once",
    //     },
    //     {
    //       key: "port",
    //       link: "port/l/",
    //     },
    //   ],
    //   // readOnly: true,
    //   required: false,
    // },
    sea: {
      isGroupTitle: false,
      title: "Place (Sea)",
      type: "select",
      options: [
        {
          value: "",
          title: "--Select Sea--",
        },
      ],
      //passing this feild value to 'place'
      required: false,
    },
    port: {
      isGroupTitle: false,
      title: "Place (Port)",
      type: "search-select",
      options: [
        {
          value: "",
          title: "--Select Port--",
        },
      ],
      //passing this feild value to 'place'
      required: false,
    },

    // ...
    // details: {
    //   isGroupTitle: false,
    //   title: "Details",
    //   type: "text",
    // },
    attachment: {
      isGroupTitle: false,
      title: "Attachment",
      type: "file",
      fileAttachmentId: "",
      required: false,
    },
    shipmanager_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Ship Manager Section",
    },
    ship_manager_comment: {
      isGroupTitle: false,
      title: "Ship Manager Comment",
      type: "textarea",
      // value: "string",
      maxlength: "4000",
      col: 12,
    },
  };