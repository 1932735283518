import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import PortForm from "../forms/PortForm";

function PortScreen(props) {
  return (
    <CommonListViewScreen
      path="/port/all" // api
      paginatedPath="/port/paged" // api
      dataPreprocessor={{
        mainTitle: "Ports",
        path: "/offhire/port", // route
        columns: [
          { header: "ID", field: "id" },
          { header: "Name", field: "name" },
          { header: "UN Code", field: "un_code" },
          { header: "Country", field: "country.name" },
        ],
      }}
      canSearch={true}
    >
      <PortForm />
    </CommonListViewScreen>
  );
}

export default PortScreen;
