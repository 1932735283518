import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import VesselForm from "../forms/VesselForm";

function VesselScreen(props) {
  return (
    <CommonListViewScreen
      path="/vessel/" // api
      paginatedPath="/vessel/paged"
      dataPreprocessor={{
        mainTitle: "Vessels",
        path: "/offhire/vessel", // route
        columns: [
          { header: "ID", field: "id" },
          { header: "Name", field: "vessel_name" },
          { header: "IMO", field: "vessel_imo" },
          { header: "Code", field: "vessel_code" },
          { header: "Mail", field: "official_mail.to" },
          { header: "Flag", field: "vessel_flag" },
          { header: "Type", field: "vessel_type" },
          { header: "Year Built", field: "vessel_year_built" },
          { header: "Builder", field: "vessel_builder" },
          { header: "Owner", field: "vessel_owner" },
          { header: "Managed By", field: "managed_by" },
          { header: "Ship Manager", field: "ship_manager.username" },
          { header: "Fleet Name", field: "fleet.fleet_name" },
          { header: "Fleet Code", field: "fleet.fleet_code" },
          { header: "Fleet Manager", field: "fleet.fleet_manager.username" },
        ],
      }}
      canEdit={true}
      // canDelete={true}
      canSearch={true}
    >
      <VesselForm />
    </CommonListViewScreen>
  );
}

export default VesselScreen;
