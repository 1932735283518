
export const AnFormConfUtil = {
    /**
     * 
     * @param {Record<string, Object>} formFields 
     * @returns 
     */
    initState: (formFields) => {
        return Object.entries(formFields)
        .reduce((acc, [key, fieldConf]) => {
            if (!fieldConf.isGroupTitle) {
                acc[key] = fieldConf.value;
            }
            return acc;
        }, {});
    },

    getOptionsArrayForSelectElement: (optionsInJSON, property_name = "name") => {
        // debugger;
        // console.log("optionsInJSON");
        let optionsArray = [];
        for (let aOption of optionsInJSON) {
          optionsArray.push({
            value: aOption.id,
            title: aOption[property_name],
            label: aOption[property_name],
          });
        }
        return optionsArray;
    },

    fieldAttributes: (fieldConf, fieldValues, fieldOptions, filter, handleValueChange, handleFileDownload) => {
        return Object.entries(fieldConf)
        .filter(([fieldKey, fieldConf]) => {
            return filter(fieldKey, fieldValues);
        })
        .map(([fieldKey, fieldConf]) => {
            return {
                ...fieldConf,
                name: fieldKey,
                value: fieldValues[fieldKey],
                onChangeHandler: handleValueChange,
                handleDownload: handleFileDownload,
                options: [
                    ...(fieldConf.options ?? [
                        { value: "", title: "--Select--", label: "--Select--" },
                    ]), 
                    ...(fieldOptions[fieldKey] ?? [])
                ],        
            }
        });
    },


}