import React, { Component } from "react";

const  IconButton = ({ iconName, onClick }) => {
    return (
      <span className="ml-3" onClick={onClick}>
        <i className={`fa fa-${iconName}`}></i>
      </span>
    );
  }

export default IconButton;