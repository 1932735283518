import React, { Component } from "react";
import CommonGridViewScreen from "../../core/pages/CommonGridViewScreen";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import MachineryTypeForm from "../forms-dialog/MachineryTypeForm";

function MachineryTypeScreen() {
    // in path last / in a must be
    return (
      <CommonListViewScreen
        // <CommonGridViewScreen
        path="/machinery/type/"
        paginatedPath="/machinery/type/paged"
        dataPreprocessor={{
          mainTitle: "Machinery Types",
          path: "/offhire/machinery-type",
          objectProcessor: (obj, doReturnObj = false) =>
            doReturnObj
              ? {
                  id: { title: "id", value: obj.id },
                  sfi_code: { title: "sfi_code", value: obj.sfi_code },
                  name: { title: "name", value: obj.name },
                  description: { title: "description", value: obj.description },
                }
              : [obj.id, obj.sfi_code, obj.name, obj.description],
          columns: [
            {  header: "ID", field: "id", width: "10%" },
            {  header: "SFI Code", field: "sfi_code", width: "20%"},
            {  header: "Name", field: "name", width: "20%"},
            {  header: "Description", field: "description", width: "40%"},
          ],
        }}
      >
        <MachineryTypeForm />
      </CommonListViewScreen>
    );
}

export default MachineryTypeScreen;
