import React, { Component } from "react";
import { Link } from "react-router-dom";


const TileButton = ({ color, icon, name, link, index }) =>{
    return (
      <div
          className="col-sm-10 col-lg-4 col-xl-3 pl-3 mt-3"
          key={"main_screen_btn_col_" + index}
          // style={{ height: "80vh"}}
        >
          <div className="bg-white  p-0" style={{ borderRadius: "14px"}}>
            {/*glassed*/}
            <Link
              to={link}
              // style={{ height: "70px" }}
              className={`btn btn-outline-${color}  btn-block m-0 pb-3`}
              key={"main_screen_btn_" + index}
              style={{
                // color: "grey",
                // minHeight: "25vh",
                // fontSize: "1.25rem",
                // background: "rgba( 255, 255, 255, 0.25 )",
                boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                // backdropFilter: "blur( 4px )",
                // WebkitBackdropFilter: "blur( 4px )",
                borderRadius: "14px",
                border: "1px solid rgba( 255, 255, 255, 0.18 )",
              }}
            >
              <i
                className={`fa fa-${icon} m-3 p-3 pb-0`}
                style={{ fontSize: "5rem" }}
              ></i>
              <br />
              {name}
            </Link>
          </div>
        </div>
    );
  }

  export default TileButton;