
export const FormValueCommercialTechnical = {
    fieldMap: () => {
        // front:back
        return {
          vessel_name: "vessel_name",
          name: "name",
          vessel_code: "vessel_code",
          voyage_number: "voyage_number",
          voyage_status: "voyage_status",
          commenced_at: "commenced_at",
          completed_at: "completed_at",
          activity: "activity",
          offhire_or_stoppage: "activity",
          delay_start_at_gmt: "commercial_delay_start_at_gmt",
          delay_end_at_gmt: "commercial_delay_end_at_gmt",
          reason: "reason",
          tci: "tci",
          tco: "tco",
          // id: "id",
          delay_year: "delay_year",
          delay_start_at_local: "delay_start_at_local",
          delay_end_at_local: "delay_end_at_local",
          hours: "hours",
          custom_offhire_days: "custom_offhire_days",
          custom_offhire_days_group: "custom_offhire_days_group",
          // ------------------
    
          // offhire_type: "offhire_type",
          // machinery_type: "machinery_type",
          // machinery_subtype: "machinery_subtype",
          // details: "details",
          // is_planned: "is_planned",
          //   offhire_or_stoppage: "offhire_or_stoppage",
          //   from_datetime: "from_datetime",
          //   to_datetime: "to_datetime",
          //   days: "days",
    
          //--------------------------
          id: "offhire_report_commercial_id",
          offhire_type: "event_type_id",
          machinery_subtype: "machinery_id",
          from_datetime: "delay_start_at_gmt",
          to_datetime: "delay_end_at_gmt",
          days: "duration_in_days",
          event: "event",
          // vessel_age: "vessel_age",
          location: "location",
          place: "place_id",
          sea: "sea_id",
          port: "port_id",
          ship_manager_comment: "ship_manager_comment",
          //--------------------------
          machinery_type: "machinery_type_id",
          sfi_code: "sfi_code",
          events_category: "events_category_id",
          is_planned: "is_planned",
          attachment: "attachment_id",
        };
      },
    preprocess: (report) => {
        return {
          ...report,
          // vessel_age: "",
          location: report.offhire_report_technical?.location ?? "",
          sea_id: report.offhire_report_technical?.sea_id ?? "",
          port_id: report.offhire_report_technical?.port_id ?? "",
          place_id:
            report.offhire_report_technical?.location?.toUpperCase() === "SEA"
              ? report.offhire_report_technical?.sea_id ?? ""
              : report.offhire_report_technical?.port_id ?? "",
    
          hours: report.hours?.toFixed(2),
          // days: report.custom_offhire_days?.toFixed(2),
          custom_offhire_days: report.custom_offhire_days?.toFixed(2),
    
          machinery_id: report.offhire_report_technical?.machinery_id,
          machinery_name: report.offhire_report_technical?.machinery?.name,
    
          machinery_type_id:
            report.offhire_report_technical?.machinery?.machinery_type_id,
          sfi_code:
            report.offhire_report_technical?.machinery?.machinery_type?.sfi_code,
          machinery_type_name:
            report.offhire_report_technical?.machinery?.machinery_type.name,
    
          event_type_id: report.offhire_report_technical?.event_type_id,
          event_type_name: report.offhire_report_technical?.event_type?.name,
    
          events_category_id:
            report.offhire_report_technical?.events_category_id ?? "",
          events_category_name:
            report.offhire_report_technical?.events_category?.name,
          is_planned: report.offhire_report_technical?.is_planned,
          ship_manager_comment:
            report.offhire_report_technical?.ship_manager_comment ?? "",
          offhire_report_commercial_id: report.id ?? 0,
          // resolving the denesting key conflicts
          event: report.offhire_report_technical?.event ?? "",
          delay_start_at_gmt:
            report.offhire_report_technical?.delay_start_at_gmt ?? "",
          delay_end_at_gmt: report.offhire_report_technical?.delay_end_at_gmt ?? "",
    
          reason: report.reason ?? "",
          commercial_delay_start_at_gmt: report.delay_start_at_gmt ?? "",
          commercial_delay_end_at_gmt: report.delay_end_at_gmt ?? "",
          attachment_id: {files:null, fileAttachmentId: report.offhire_report_technical?.attachment_id},
    
        };
      },
}