import FilterSelect from "../FilterSelect";

export const YearFilter = ({ defaultValue, onChange }) => {
    let currentYear = new Date().getFullYear();

    return <FilterSelect
    label="Year"
    closeMenuOnSelect={false}
    isMulti={true}
    id="report_filter_year"
    onChange={onChange}
    options={[
      ...Array(10)
        .fill(currentYear)
        .map((year, index) => {
          return {
            value: currentYear - index,
            label: currentYear - index,
          };
        }),
      // { value: "1111", label: "All" },
    ]}
    defaultValue={defaultValue}
  ></FilterSelect>;
};