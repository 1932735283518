
export const ReportTableNoItems = () => {
    return (
    <tr>
        <td colSpan="17">
          <div className="display-4 p-5">
            {"No Reports are available under this category"}
          </div>
        </td>
      </tr>
    );
};