import React from "react";
import AnInputElement from "./AnInputElement";

class AnFormGroup extends React.Component {
  render() {
    const { attribute, validation, isCustom } = this.props;

    let validClass = validation?.isValid ? "is-valid" : "is-invalid";
    validClass = validation?.isChecked ? validClass : "";
    let validationMessage = validation?.message ? validation.message : "";

    attribute.style = { fontSize: "0.7rem" };
    const formFieldDiVClassName = isCustom
      ? "col"
      : typeof attribute?.col === 'number' ? `col-${attribute.col}`:"col-sm-12 col-md-6 col-lg-3";
    return (
      <React.Fragment>
        <React.Fragment>
          {attribute?.isGroupTitle === true ? (
            <div className="form-group row mb-0" key={attribute.name}>
              <h5
                className="col-sm-12 col-lg-12 pt-3 mb-0 text-primary"
                style={{ fontSize: "0.9rem" }}
              >
                {attribute.title}
              </h5>
            </div>
          ) : (
            <div
              className={"form-group " + formFieldDiVClassName}
              key={attribute.name}
            >
              {attribute?.title && (
                <label
                  // className="col-auto col-form-label col-form-label-sm px-1"
                  className="label-sm p-0"
                  htmlFor={attribute.name}
                  style={attribute.style}
                >
                  {attribute.title}
                  {attribute?.required && <span className="text-danger"> *</span>}
                </label>
              )}
              <AnInputElement attribute={attribute} validClass={validClass} />
              {validationMessage && (
                <div className="valid-feedback">{validationMessage}</div>
              )}
            </div>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default AnFormGroup;
