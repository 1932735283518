import React from "react";

const AnFormSubmitButton = ({ label, onSubmit, inProgress, error, isSmall = false}) => {
    return (
        <div className="form-group row  m-0 p-0 pt-1 justify-content-end">
          <div className="col-sm-12 col-md-6">
            {inProgress && (
              <React.Fragment>
                <span className="text-danger" style={{ fontSize: "0.7rem" }}>
                  {" "}
                  <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                  <span className="sr-only">Genrating...</span>
                </span>
              </React.Fragment>
            )}
            {!error && (
              <span className="text-danger" style={{ fontSize: "0.7rem" }}>
                {error}
              </span>
            )}
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <input
              // type="submit"
              onClick={onSubmit}
              className="form-control btn btn-outline-primary btn-sm p-0"
              name="submit"
              id="submit"
              value={label}
              style={{ fontSize: "0.6rem" }}
              disabled={inProgress}
            />
          </div>
        </div>
      );
}

export default AnFormSubmitButton;