import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";

function PortForm(props){
    
    const labels = {
        name : {
            isGroupTitle: false,
            title: "Name",
            type: "text",
            value: "",
        },
        un_code: {
            isGroupTitle: false,
            title: "UN Code",
            type: "text",
            value: "",
        },
        country: {
            isGroupTitle: false,
            title: "Country",
            type: "select",
            options: [
                {
                    value: "",
                    title: "-Select Country-",
                },
            ],
            optionsFetch:{
                api:  "/country/all",
                responseHandler: (o)=>o?.map((a)=>({value:a.id, title:a.name})),
            },
            value: "",
        },
    };

    const getFieldsMap = () => {
        // front:back
        return {
            "name": "name",
            "un_code": "un_code",
            "country": "country_id",
        };
    }

    return (
        <AnCommonFormDialog
            {...props}
            header="Add Port"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/port/"
        />
    )

}

export default PortForm;