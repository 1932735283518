import { StatusPill } from "../../util/first"

export const AnFormStatus = ({ status, children }) => {
    return (
        <>
        <div className="row justify-content-end">
          <div className="col-auto">
            <StatusPill status={status} />
          </div>
        </div>
        {children}
        <div className="row justify-content-start">
          <div className="col-auto">
            <StatusPill status={status} />
          </div>
        </div>
        </>
    )
}