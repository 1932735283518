import { fileDownload, fileUpload } from "../../core/application/services/file.service";
import { OffhireAPIRoutes } from "../application/apiSources";
import { loadMachinery, loadPorts } from "../util/data";
import { OffhireReportService } from "./OffhireReportService";
import { EventCategoryService, EventTypeService, SeaService } from "./services";

export const OffhireReportFormService = {
  /**
   * 0 : EventCatergory,
   * 1 : EventType,
   * 2 : Sea,
   * 3 : Machinery,
   * 4 : Port,
   */
  getFormData: () => {
    return Promise.all([
      EventCategoryService.get(),
      EventTypeService.get(),
      SeaService.get(),
      loadMachinery(),
      loadPorts(),
    ]);
  },

  getOptionsArrayForSelectElement : (optionsInJSON, property_name = "name") => {
    let optionsArray = [];
    for (let aOption of optionsInJSON) {
      optionsArray.push({
        value: aOption.id,
        title: aOption[property_name],
        label: aOption[property_name],
      });
    }
    return optionsArray;
  },

  uploadAttachment : (files, technicalReportId) => {
    if (!files) return;
    isNaN(technicalReportId) && (technicalReportId=0);
    // API
    const FORM_FILE_UPLOAD_API = OffhireAPIRoutes.REPORT_ATTATCHMENT + '/' + technicalReportId;

    return fileUpload(FORM_FILE_UPLOAD_API, files);
  },


  downloadAttachment : (technicalReportId) => {
    if (!technicalReportId) return;
    // API
    const FORM_FILE_UPLOAD_API = OffhireAPIRoutes.REPORT_ATTATCHMENT + '/' + technicalReportId;

    return fileDownload(FORM_FILE_UPLOAD_API);
  },


  createReport : (report, withComm = false) => {
    return OffhireReportService.create(report, withComm);
  },

  updateReport : (report) => {
    return OffhireReportService.update(report);
  },

  addReport : (report) => {
    return OffhireReportService.add(report);
  },

  approveReport : (report) => {
    return OffhireReportService.approve(report);
  },


  do : (action, payload) => {

    if (action === "update")
      return OffhireReportService.update(payload);
    
    if (action === "add")
      return OffhireReportService.add(payload);

    if (action === "approval")
      return OffhireReportService.approve(payload);

    return OffhireReportService.create(payload);
  }


}


export default OffhireReportFormService;